import React, { useMemo, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { Doughnut } from "react-chartjs-2";
import sortImg from "assets/img/sort.png";
import SubmitLoader from "components/SubmitLoader";
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  FormGroup,
  Collapse,
  Label,
  Input,
  Row,
  Spinner,
  Col,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { sortList, drawCanvas, notify } from "components/functions";
import { useDispatch, useSelector } from "react-redux";

import { getAllProductCount } from "store/AddGroupAction";
// AllProductCountDataSuccess
function CreateMedicalProposal() {
  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const [loadingData, setLoadingData] = React.useState(true);
  const getOptionUrl = process.env.REACT_APP_API_URL + "/get-options";
  const getTemplateOptionUrl = process.env.REACT_APP_API_URL + "/get-templates-by-location";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [countrytOptions, setcountrytOptions] = useState([]);
  const [templateOptions, settemplateOptions] = useState([]);
  const [insurerOptions, setinsurerOptions] = useState([]);
  const [templateProducts, setTemplateProducts] = useState([]);
  const [templateFavProducts, setTemplateFavProducts] = useState([]);
  const [person, setperson] = useState();
  const [location, setlocation] = useState();
  const [template, setTemplate] = useState();
  const [insurers, setInsurers] = useState([]);
  const [diductible, setDiductible] = useState(false);
  const [annualPhysical, setAnnualPhysical] = useState(false);
  const [evacuation, setEvacuation] = useState(false);
  const [hospitalisation, setHospitalisation] = useState("no");
  const [outpatient, setOutpatient] = useState("no");
  const [dental, setDental] = useState("no");
  const [maternity, setMaternity] = useState("no");
  const [nationality, setnationality] = useState();
  const [Premiumtype, setPremiumtype] = useState("premium");
  const [showFilterResult, setShowFilterResult] = useState(false);
  const [personNoState, setpersonNoState] = useState("");
  const [locationState, setlocationState] = useState("");
  const [templateState, setTemplateState] = useState("");
  const [nationalityState, setnationalityState] = useState("");
  const [productResult, setproductResult] = useState({ data: {} });
  const [openedCollapses, setOpenedCollapses] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [productCount, setProductCount] = React.useState("");
  const [resultCount, setResultCount] = React.useState("");
  const [selectedPlans, setSelectedPlans] = useState(0);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  let history = useHistory();
  const params = useParams();
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const dispatch = useDispatch();
  const { AllProductCountDataSuccess } = useSelector(
    (state) => state.getAllProductCountDataRe
  );
  console.log("nationality", nationality);
  // const name = localStorage.getItem('name');
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
    } else {
      openedCollapses.push(collapse);
      setOpenedCollapses([...openedCollapses, collapse]);
    }
  };
  let proposal_products = useMemo(() => [], []);
  let personOptions = [];
  let additionlInfo = [];
  let policyHolderAgeOption = [];
  let additionalPersonAge = [];
  for (let index = 1; index <= 8; index++) {
    personOptions.push({ value: index, label: index });
  }
  for (let index = 0; index <= 90; index++) {
    policyHolderAgeOption.push({ value: index, label: index });
  }
  for (let index = 0; index <= 90; index++) {
    additionalPersonAge.push({ value: index, label: index });
  }
  const genderOptions = [
    { value: "", label: "Gender", isDisabled: true },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  useEffect(() => {
    async function getOptions() {
      fetch(getOptionUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            setcountrytOptions(data.countries);
            setinsurerOptions(data.insurer);
            setLoadingData(false);
          } else {
            setLoadingData(false);
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getOptions();
    }
  }, []);

  useEffect(() => {
    async function getTemplateOptions() {
      console.log("location data....",location?.value);
      if(location)
      {
      fetch(`${getTemplateOptionUrl}?location=${location?.value}`, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            settemplateOptions(data.templates);
          }
        });
    }}
    getTemplateOptions();
  
  }, [location]);

  useEffect(() => {
    if (AllProductCountDataSuccess?.data?.success == true) {
      setProductCount(AllProductCountDataSuccess?.data?.dataCount);
    }
  }, [AllProductCountDataSuccess]);

  useEffect(() => {
    if(resultCount>0)
    {
    let policyText = selectedPlans==1?"policy":"policies";
    let selectedMsg = "You have "+selectedPlans+" "+policyText+" selected";
    notify(selectedMsg, 1, notificationAlert);
    }
  }, [selectedPlans]);

  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };
  const handleProposalSelection = (e) => {
    const checked = e.target.checked;
    if (checked) {
      if(selectedPlans < 7){
        proposal_products.push(
          e.target.value
        );
        setSelectedPlans(selectedPlans+1);
      }
      else{
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="info"
            confirmBtnText="OK"
            btnSize=""
          >
            You are unable to select more than 7 policies per proposal.
          </ReactBSAlert>
        );
        e.target.checked = false;
      }
    } else {
      var index = proposal_products.indexOf(
        e.target.value
      );
      if (index > -1) {
        proposal_products.splice(index, 1);
        setSelectedPlans(selectedPlans-1);
        setFavoriteProducts((prevFavs) => prevFavs.filter((id) => id !== e.target.value));
      }
    }
  }
  
  const handleFavoriteSelection = (productId) => {
    setFavoriteProducts((prevFavs) =>
      prevFavs.includes(productId)
        ? prevFavs.filter((id) => id !== productId) // Uncheck favorite
        : [...prevFavs, productId] // Check favorite
    );
  };
  const [formValues, setFormValues] = useState([
    { gender: "", age: "", relation_status: "self" },
  ]);
  const [formErrors, setFormErrors] = useState([]);
  const filterArray = (req, value) => {
    for (let index = 0; index <= req.length - 1; index++) {
      //console.log(req[index]['value']);
      if (req[index]["value"] == value) {
        return true;
        break;
      }
    }
  };
  const CreateProposal = (is_template=false, productIds=[], favIds=[]) => {
    const productData = is_template===true?productIds:proposal_products;
    const favProductData = is_template===true?favIds:favoriteProducts;
    const createMedicalProposalUrl =
      process.env.REACT_APP_API_URL + "/create-medical-proposal";
    var data = {
      hospitalization: hospitalisation,
      outpatient: outpatient,
      dental: dental,
      maternity: maternity,
      location: location.value,
      no_of_person: person.value,
      persons: formValues,
      products: productData,
      fav_products: favProductData,
      client_id: params.client_id,
      Premiumtype: Premiumtype,
    };

    if (productData.length > 0) {
      setShowLoader(true);
      fetch(createMedicalProposalUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          if (!data.success) {
          } else {
            notify("Proposal Created Successfully.", 2, notificationAlert);
            setShowLoader(false);
            setTimeout(function () {
              history.push("/admin/opportunities/" + params.client_id);
            }, 2000);
          }
        });
    } else {
      notify("Please Select a plan to continue.", 3, notificationAlert);
    }
  };

  const getProductLimit = async () => {
    var data = {
      hospitalization: hospitalisation,
      outpatient: outpatient,
      dental: dental,
      maternity: maternity,
      location: location.value,
      no_of_person: person.value,
      persons: formValues,
      nationality: nationality?.value,
      insurer_ids: insurers?insurers.map(insurer => insurer.value):[],
      diductible: diductible,
      annual_physical: annualPhysical,
      evacuation: evacuation,
    };
    await dispatch(getAllProductCount(data, loggedUser.token));
  };

  const getProduct = async (productlimit) => {
      setShowLoader(true);
      if (productlimit === 0) {
        var getMedicalUrl =
          process.env.REACT_APP_API_URL + "/get-medical-products/ASC/premium/1";
      } else {
        var getMedicalUrl =
          process.env.REACT_APP_API_URL +
          `/get-medical-products/ASC/premium/1/${productlimit}`;
      }
      var data = {
        hospitalization: hospitalisation,
        outpatient: outpatient,
        dental: dental,
        maternity: maternity,
        location: location?.value,
        no_of_person: person?.value,
        persons: formValues,
        nationality: nationality?.value,
        insurer_ids: insurers?insurers.map(insurer => insurer.value):[],
        diductible: diductible,
        annual_physical: annualPhysical,
        evacuation: evacuation,
      };
    
      fetch(getMedicalUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("productlimit", data);
          if (!data.success) {
          } else {
            setproductResult(data);
            setResultCount(data.limit);
            setShowLoader(false);
          }
        });
  };
  let handleChange = (i, e, type, name = "") => {
    let newFormValues = [...formValues];

    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    if (i === 0) {
      newFormValues[i]["relation_status"] = "self";
    }
    setFormValues(newFormValues);
  };

  const LoadMore = () => {
    if (productResult) {
      var productLimit = productResult.limit;
      getProduct(productLimit);
    }
  };

  async function getTemplateProducts(template_id){
    fetch(process.env.REACT_APP_API_URL+"/get-template/"+template_id, {
      method: "GET",
      headers: headers,
    })
    .then(function(response){ 
      return response.json();   
    })
    .then(function(data){ 
      console.log(data);  
      if(data.success){    
        console.log("template products....", data.selectedProducts);  
        const ids = data.selectedProducts.map(product => product.product_id);
        const favIds = data.selectedProducts.filter(product => product.is_favourite == 1).map(product => product.product_id);
        CreateProposal(true, ids, favIds);
      }
    });
  }
  
  useEffect(() => {
    if (personNoState === "has-success") {
      console.log("previous form values",formValues);
      const value = person.value;
      const newFormData = [...formValues];
      if (value > formValues.length) {
        // Add more user entries if number of persons increased
        for (let i = formValues.length; i < value; i++) {
          newFormData.push({ gender: "", age: "", relation_status: "" });
        }
      } else if (value < formValues.length) {
        // Remove extra user entries if number of persons decreased
        newFormData.splice(value);
      }
      setFormValues(newFormData);
    } 
  }, [person]);

  const handleSubmitAction = (action) => {
    console.log("action.....",action);
    if (personNoState !== "has-success") {
      setpersonNoState("has-danger");
    }
    if (locationState !== "has-success") {
      setlocationState("has-danger");
    }
    if (nationalityState !== "has-success") {
      setnationalityState("has-danger");
    }
    if(action=="generate_template_pdf" && templateState !== "has-success"){
        setTemplateState("has-danger");
    }
    let flag = "no-error";
    let formErrors = [];
    for (var i = 0; i < formValues.length; i++) {
      const personformErrors = { gender: '', age: '' };
      if (formValues[i].gender == ""){
        personformErrors.gender = 'This field is required'; 
        flag = "error";
      }
      if (formValues[i].age == ""){
        personformErrors.age = 'This field is required'; 
        flag = "error";
      } 
      formErrors.push(personformErrors);
    }
    setFormErrors(formErrors);
    
    if (personNoState === "has-success" && locationState === "has-success" && flag === "no-error" && nationalityState === "has-success") {
      if(action=="filter_result")
      {
        proposal_products.splice(0, proposal_products.length);
        setFavoriteProducts([]);
        setSelectedPlans(0);
        getProduct(0);
        getProductLimit();
        setShowFilterResult(true);
      } else if(action=="generate_template_pdf" && templateState === "has-success"){
        console.log("Selected Template", template);
        console.log("generate pdf action...");
        getTemplateProducts(template);
        console.log("TemplateProducts....", templateProducts);
        console.log("TemplateFavProducts....", templateFavProducts);
      }
      
    }
  };
  
  return (
    <div className="content">
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      {alert}
      <Row>
        {loadingData ? (
          <p>Loading Please wait...</p>
        ) : (
          <Col md="12">
            <Card style={{ padding: '10px', alignItems: 'stretch' }}>
              <CardBody>
                <Row>
                  {/* First Part: Adjust the column to take 3 out of 12 columns */}
                  <Col xs="4" style={{borderRight: '2px solid #3FA9F5'}}>
                    <FormGroup>
                      <Row>
                        <Col md="6">
                          <FormGroup className={`has-label ${locationState}`}>
                            <label>Select Location</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) => {
                                setlocation(e);
                                if (e.value === "") {
                                  setlocationState("has-danger");
                                } else {
                                  setlocationState("has-success");
                                }
                              }}
                              options={countrytOptions}
                              placeholder="Select Location"
                            />
                            {locationState === "has-danger" ? (
                              <label className="error">This field is required.</label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col md="6">
                          <FormGroup className={`has-label ${personNoState}`}>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) => {
                                setperson(e);
                                if (e.value === "") {
                                  setpersonNoState("has-danger");
                                } else {
                                  setpersonNoState("has-success");
                                }
                              }}
                              options={personOptions}
                              placeholder="Select Number of Insured Persons"
                            />
                            {personNoState === "has-danger" ? (
                              <label className="error">This field is required.</label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className={`has-label ${nationalityState}`}>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) => {
                                setnationality(e);
                                if (e.value === "") {
                                  setnationalityState("has-danger");
                                } else {
                                  setnationalityState("has-success");
                                }
                              }}
                              options={countrytOptions}
                              placeholder="Select Nationality"
                            />
                            {nationalityState === "has-danger" ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col md="6">
                          <FormGroup className={`has-label`}>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) =>
                                handleChange(0, e, "select", "age")
                              }
                              options={policyHolderAgeOption}
                              placeholder="Select Age for policy holder"
                            />
                            {formErrors[0]?.age ? (
                              <label className="error">{formErrors[0].age}</label>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className={`has-label`}>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) =>
                                handleChange(0, e, "select", "gender")
                              }
                              options={genderOptions}
                              placeholder="Select Gender for policy holder"
                            />
                            {formErrors[0]?.gender ? (
                              <label className="error">{formErrors[0].gender}</label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      {formValues.map((element, index) =>
                        index + 1 < formValues.length ? (
                          <Row key={index}>
                            <Col className="ml-auto mr-auto" md="6">
                              <FormGroup className={`has-label`}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  onChange={(e) => {
                                    handleChange(index + 1, e, "select", "age");
                                    //setPersonInfoState("has-success");
                                  }}
                                  options={additionalPersonAge}
                                  placeholder={`Select Age for person ${index + 1}`}
                                />
                                {formErrors[index+1]?.age ? (
                                  <label className="error">{formErrors[index+1].age}</label>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col className="ml-auto mr-auto" md="6">
                              <FormGroup className={`has-label`}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  onChange={(e) => {
                                    handleChange(
                                      index + 1,
                                      e,
                                      "select",
                                      "gender"
                                    );
                                  }}
                                  options={genderOptions}
                                  placeholder={`Select Gender for person ${index + 1}`}
                                />
                                {formErrors[index+1]?.gender ? (
                                  <label className="error">{formErrors[index+1].gender}</label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null
                      )}
                    </FormGroup>
                  </Col>
                  {/* Second Part: Also adjust column to take 3 out of 12 columns */}
                  <Col xs="3" style={{ textAlign: 'center', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                    <Row>
                        <Col md="12">
                          <FormGroup className={`has-label ${templateState}`}>
                            <label>Select PDF Template</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              onChange={(e) => {
                                setTemplate(e.value);
                                if (e.value === "") {
                                  setTemplateState("has-danger");
                                } else {
                                  setTemplateState("has-success");
                                }
                              }}
                              options={templateOptions}
                              placeholder="Select Template"
                            />
                            {templateState === "has-danger" ? (
                              <label className="error">This field is required.</label>
                            ) : null}
                          </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Button color="primary" onClick={() => handleSubmitAction('generate_template_pdf')}>
                            Generate PDF
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {/* Third Part: Reduce width to fit everything in one row */}
                  <Col xs="5" style={{borderLeft: '2px solid #3FA9F5'}}>
                    
                      <Row>
                        <Col md="5">
                          <FormGroup className={`has-label`}>
                            <label>Select Your Insurers:</label>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Select Your Insurers"
                              hideSelectedOptions={false}
                              isMulti
                              isClearable={false}
                              options={insurerOptions}
                              components={{ MultiValueRemove }}
                              onChange={(e) => {
                                setInsurers(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3" className="pr-0 pl-0">
                          
                          <Button color="primary" onClick={() => handleSubmitAction('filter_result')} style={{ marginTop: '25px', marginLeft: '5px'}}>
                            Filter Results
                          </Button>
                        </Col>
                        <Col md="4" className="pr-0">
                          
                          <Button color="primary" disabled={selectedPlans > 0?false:true} onClick={(e) => CreateProposal()} style={{ marginTop: '25px' }}>
                            Create Proposal
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col md="4" className="pr-0">
                        </Col>
                        <Col md="4" className="pr-0">
                        <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={hospitalisation}
                                onChange={(e) => {
                                  setHospitalisation(e.target.checked?"yes":"no");
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Hospitalisation</strong>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={outpatient}
                                onChange={(e) => {
                                  setOutpatient(e.target.checked?"yes":"no");
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Outpatient</strong> 
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={dental}
                                onChange={(e) => {
                                  setDental(e.target.checked?"yes":"no");
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Dental</strong>  
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={maternity}
                                onChange={(e) => {
                                  setMaternity(e.target.checked?"yes":"no");
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Maternity</strong>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="4" className="pr-0">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={diductible}
                                onChange={(e) => {
                                  setDiductible(e.target.checked);
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Deductible</strong> 
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={evacuation}
                                onChange={(e) => {
                                  setEvacuation(e.target.checked);
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Evacuation</strong>  
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                value={annualPhysical}
                                onChange={(e) => {
                                  setAnnualPhysical(e.target.checked);
                                }}
                              />
                              <span className="form-check-sign" />
                              <strong>Annual Physical</strong>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className={showFilterResult ? "" : "d-none"}>
              <CardHeader>
                <h5></h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <ul className="list-group" id="id01">
                      <li className="list-group-item bg-dark text-light">
                        <Row>
                          <Col
                            md="1"
                            className="pl-1 ml-auto mr-auto text-center font-weight-bold"
                          >
                          Insurer
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Hospitalization
                          </Col>
                          <Col
                            md="1"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Outpatient
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Dental
                          </Col>
                          <Col
                            md="1"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Maternity
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                            onClick={(e) => {
                              sortList("rating");
                            }}
                          >
                            Customer Rating <img src={sortImg}></img>
                          </Col>
                          <Col
                            md="2"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            <p
                              onClick={(e) => {
                                sortList(Premiumtype);
                              }}
                            >
                              Premium <img src={sortImg}></img>
                            </p>
                            <FormGroup className={`has-label`}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={(e) => setPremiumtype(e.value)}
                                options={[
                                  { value: "premium", label: "Monthly" },
                                  {
                                    value: "annual_premium",
                                    label: "Annually",
                                  },
                                ]}
                                defaultValue={{
                                  value: "Monthly",
                                  label: "Monthly",
                                }}
                                placeholder="Premium Type"
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            md="1"
                            className="ml-auto mr-auto text-center font-weight-bold"
                          >
                            Recommend
                          </Col>
                        </Row>
                      </li>

                      {productResult.data.length > 0 ? (
                        productResult.data.map((element, index) => {
                          const isSelected = proposal_products.includes(element.product.product_id);
                          return (
                          <li
                            key={index}
                            className="list-group-item bg-light mt-4"
                          >
                            <Row key={index}>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                <img
                                  src={element.product.insurer_logo}
                                  width="100"
                                  height="100"
                                ></img>
                              </Col>
                              <Col
                                md="2"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.hospitalization_radio ===
                                "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.hospitalization_radio ===
                                  "text" ? (
                                  element.product.hospitalization_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.outpatient_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.outpatient_radio ===
                                  "text" ? (
                                  element.product.outpatient_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="2"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.dental_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.dental_radio === "text" ? (
                                  element.product.dental_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                {element.product.maternity_radio === "tick" ? (
                                  <i className="nc-icon nc-check-2 text-success font-weight-bold h3"></i>
                                ) : element.product.maternity_radio ===
                                  "text" ? (
                                  element.product.maternity_text
                                ) : (
                                  <i className="nc-icon nc-simple-remove text-danger font-weight-bold h3"></i>
                                )}
                              </Col>
                              <Col
                                md="2"
                                data-val={element.product.customer_rating}
                                className="ml-auto mr-auto text-center ratings"
                              >
                                <Doughnut
                                  data={drawCanvas(
                                    element.product.customer_rating,
                                    "data"
                                  )}
                                  options={drawCanvas(
                                    element.product.customer_rating,
                                    "options"
                                  )}
                                  className="ct-chart ct-perfect-fourth"
                                  height={20}
                                  width={20}
                                />
                              </Col>
                              <Col
                                md="2"
                                data-val={element.product.premium}
                                data-val1={element.product.annual_premium}
                                className="ml-auto mr-auto text-center premium"
                              >
                                {Premiumtype === "premium"
                                  ? `${element.product.premium} USD / Month`
                                  : element.product.annual_premium === 0
                                  ? "N/A"
                                  : `${element.product.annual_premium} USD / Year`}

                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={isSelected}
                                      value={element.product.product_id}
                                      onChange={handleProposalSelection}
                                    />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col
                                md="1"
                                className="ml-auto mr-auto text-center"
                              >
                                &nbsp;
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={favoriteProducts.includes(element.product.product_id)}
                                      value={element.product.product_id}
                                      onChange={() => handleFavoriteSelection(element.product.product_id)}
                                      disabled={!isSelected}
                                    />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="text-center">
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      className="text-primary font-weight-bold"
                                      aria-expanded={openedCollapses.includes(
                                        "collapseOne"
                                      )}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() =>
                                        collapsesToggle("collapse" + index)
                                      }
                                    >
                                      See Full Benefits
                                      <br />
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={openedCollapses.includes(
                                      "collapse" + index
                                    )}
                                  >
                                    <CardBody className="text-left">
                                      <Row>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Provider
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.insurer_title}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Annual Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.annual_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Hospital Cover
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.hospital_cover}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Physiotherapy
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.physiotherapy}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Dental Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.dental_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Maternity Wait Period
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .maternity_wait_period
                                              }
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Plan name
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.plan_name}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Deductible/Excess
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .deductible_excess
                                              }
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Oncology/Cancer
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.oncology_cancer}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Vaccinations
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.vaccinations}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Dental Wait Period
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {
                                                element.product
                                                  .dental_wait_period
                                              }
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Evacuation
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.evacuation}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="4">
                                          <Row>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Product
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.product_type}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Network
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.network}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Outpatient Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.outpatient_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Annual Physical
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.annual_physical}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Maternity Limit
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.maternity_limit}
                                            </Col>

                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-muted"
                                            >
                                              &#9642; Area of Cover
                                            </Col>
                                            <Col
                                              md="6"
                                              className="mt-2 h6 text-capitalize text-muted"
                                            >
                                              {element.product.area_of_cover}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                              </Col>
                            </Row>
                          </li>
                        )})
                      ) : (
                        <li className="list-group-item">No Data Found !</li>
                      )}
                    </ul>
                  </Col>

                  <Col md="12" style={{ textAlign: "right" }}>
                    <p><b>Showing: {resultCount} entries</b></p>
                    <p><b>Selected: {selectedPlans}</b></p>
                  </Col>
                  {productCount == resultCount ? null : (
                    <Col md="12" className="text-center">
                      {" "}
                      {!productCount ? (
                        <Spinner type="grow" color="primary" children={false} />
                      ) : (
                        <Button
                          color="primary"
                          onClick={(e) => {
                            LoadMore();
                          }}
                        >
                          Load More
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
export default CreateMedicalProposal;
