/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ServerPagination from "components/ReactTable/ServerPagination";
import AppTable from "components/ReactTable/AppTable";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { getProducts } from "store/TableAction";
import { TableSliceAction } from "store/TableDataSlice";
// reactstrap components
import { Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { notify } from "components/functions";
import OpportunityFilter from "components/Filters/opportunity-filter";
import { FetchAllOptionApi } from "store/AddGroupAction";
function Opportunities(props) {
  const dispatch = useDispatch();
  const { pageData, currentPage, sortColumn, sortDirection, LeadFilter } =
    useSelector((state) => state.tableData);
  const [consultantOptions, setconsultantOptions] = useState([]);
  const [supporManagerOptions, setSupporManagerOptions] = useState([]);
  const [countryOption, setcountryOption] = useState([]);

  const notificationAlert = React.useRef();
  const [alert, setAlert] = React.useState(null);
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));

  const [loadingData, setLoadingData] = useState(true);
  const [SelectedLeads, setSelectedLeads] = useState([]);
  const [RefreshTable, setRefreshTable] = useState();
  const { AllOptionsData } = useSelector((state) => state.getAllOptionsDataRe);

  // console.log("pageData", pageData)
  let options;
  const history = useHistory();
  useEffect(() => {
    dispatch(TableSliceAction.reset());
  }, []);
  useEffect(() => {
    // console.log("working in useeffect    report api")
    if (props.leadType === "my") {
      dispatch(
        TableSliceAction.updateFilterParam({
          field: "o_status",
          value: "Opportunity Open",
        })
      );
    }

    if (props.leadType === "report-group" || props.leadType === "report") {
      // console.log("working in report api")
      const getAllOptions = async () => {
        await dispatch(FetchAllOptionApi(loggedUser.token));
      };
      getAllOptions();
    }
  }, []);

  useEffect(() => {
    if (AllOptionsData?.data?.success == true) {
      const apiResponse = AllOptionsData?.data?.users;
      const sortedOptions = [...apiResponse].sort((a, b) => {
        if (a.label === "N/A") {
          return -1;
        }
        if (b.label === "N/A") {
          return 1;
        }

        return a.label.localeCompare(b.label);
      });

      const newSortedOptions = sortedOptions.filter((val) => {
        if (
          val.label === "N/A" ||
          val.label === "Claire Dimla" ||
          val.label === "Matthew Thomson" ||
          val.label === "Michael Smith" ||
          val.label === "Nim Hiran" ||
          val.label === "Onnapa Sangin" ||
          val.label === "Peach Nante" ||
          val.label === "Sue Soe" ||
          val.label === "Sunny Sakchira" ||
          val.label === "Tristan Slock" ||
          val.label === "Turk Rirksom" ||
          val.label === "Darren Wise" ||
          val.label === "Priya Ghogar" ||
          val.label == "Sally Skeggs"
        ) {
          return val;
        }
      });

      const staffUsers = AllOptionsData?.data?.staffUsers;
      const staffUserssortedOptions = [...staffUsers].sort((a, b) => {
        if (a.label === "N/A") {
          return -1;
        }
        if (b.label === "N/A") {
          return 1;
        }

        return a.label.localeCompare(b.label);
      });

      const newStaffUserssortedOptions = staffUserssortedOptions.filter(
        (val) => {
          if (
            val.label === "N/A" ||
            val.label === "Amy Singh" ||
            val.label === "Claire Dimla" ||
            val.label === "Matthew Thomson" ||
            val.label === "Michael Smith" ||
            val.label === "Nim Hiran" ||
            val.label === "Onnapa Sangin" ||
            val.label === "Peach Nante" ||
            val.label === "Sue Soe" ||
            val.label === "Sunny Sakchira" ||
            val.label === "Turk Rirksom" ||
            val.label === "Darren Wise" ||
            val.label == "Sally Skeggs"
          ) {
            return val;
          }
        }
      );

      setconsultantOptions(newSortedOptions);
      setSupporManagerOptions(newStaffUserssortedOptions);
      setcountryOption(AllOptionsData?.data?.countries);
    }
  }, [AllOptionsData]);

  // console.log("consultantOptions", consultantOptions)
  const AllColumn = [
    {
      Header: "Enquiry Date",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : "",
      sorCol: sortColumn === "created_at" ? "yes" : "no",
    },

    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    // {
    //   Header: "Phone",
    //   accessor: "phone",
    //   sortdir: sortColumn === "phone" ? sortDirection : "",
    //   sorCol: sortColumn === "phone" ? "yes" : "no",
    // },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },

    // {
    //   Header: "Activity",
    //   accessor: "activity",
    //   sortdir: sortColumn === "activity" ? sortDirection : "",
    //   sorCol: sortColumn === "activity" ? "yes" : "no",
    // },
    // {
    //   Header: "Activity Date",
    //   accessor: "activity_date",
    //   sortdir: sortColumn === "activity_date" ? sortDirection : "",
    //   sorCol: sortColumn === "activity_date" ? "yes" : "no",
    // },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    // {
    //   Header: "Follow Up Date",
    //   accessor: "followUpDate",
    //   sortdir: sortColumn === "followUpDate" ? sortDirection : "",
    //   sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    // },
    // {
    //   Header: "Priority",
    //   accessor: "priority",
    //   sortdir: sortColumn === "priority" ? sortDirection : "",
    //   sorCol: sortColumn === "priority" ? "yes" : "no",
    // },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  const OpportunityOpen = [
    {
      Header: "Enquiry Date",
      accessor: "created_at",
      sortdir: sortColumn === "created_at" ? sortDirection : "",
      sorCol: sortColumn === "created_at" ? "yes" : "no",
    },

    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortdir: sortColumn === "phone" ? sortDirection : "",
      sorCol: sortColumn === "phone" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },

    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
    {
      Header: "Activity Date",
      accessor: "activity_date",
      sortdir: sortColumn === "activity_date" ? sortDirection : "",
      sorCol: sortColumn === "activity_date" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },

    {
      Header: "Follow Up Date",
      accessor: "followUpDate",
      sortdir: sortColumn === "followUpDate" ? sortDirection : "",
      sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    },
    {
      Header: "Priority",
      accessor: "priority",
      sortdir: sortColumn === "priority" ? sortDirection : "",
      sorCol: sortColumn === "priority" ? "yes" : "no",
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    // },
  ];

  const AccountPending = [
    {
      Header: "Sales Date",
      accessor: "current_status_date",
      sortdir: sortColumn === "current_status_date" ? sortDirection : "",
      sorCol: sortColumn === "current_status_date" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Start/Renewal Date",
      accessor: "start_date",
      sortdir: sortColumn === "start_date" ? sortDirection : "",
      sorCol: sortColumn === "start_date" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },

    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
    {
      Header: "Activity Date",
      accessor: "activity_date",
      sortdir: sortColumn === "activity_date" ? sortDirection : "",
      sorCol: sortColumn === "activity_date" ? "yes" : "no",
    },
    {
      Header: "Insurer",
      accessor: "insurer_name",
      sortdir: sortColumn === "insurer_name" ? sortDirection : "",
      sorCol: sortColumn === "insurer_name" ? "yes" : "no",
    },

    {
      Header: "Follow Up Date",
      accessor: "followUpDate",
      sortdir: sortColumn === "followUpDate" ? sortDirection : "",
      sorCol: sortColumn === "followUpDate" ? "yes" : "no",
    },
    {
      Header: "Priority",
      accessor: "priority",
      sortdir: sortColumn === "priority" ? sortDirection : "",
      sorCol: sortColumn === "priority" ? "yes" : "no",
    },
  ];
  const report = [
    {
      Header: "Status",
      accessor: "status",
      sortdir: sortColumn === "status" ? sortDirection : "",
      sorCol: sortColumn === "status" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "group_name",
      sortdir: sortColumn === "group_name" ? sortDirection : "",
      sorCol: sortColumn === "group_name" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "group_location",
      sortdir: sortColumn === "group_location" ? sortDirection : "",
      sorCol: sortColumn === "group_location" ? "yes" : "no",
    },
    {
      Header: "Phone",
      accessor: "group_admin_phone",
      sortdir: sortColumn === "group_admin_phone" ? sortDirection : "",
      sorCol: sortColumn === "group_admin_phone" ? "yes" : "no",
    },
    {
      Header: "Email",
      accessor: "group_admin_email",
      sortdir: sortColumn === "group_admin_email" ? sortDirection : "",
      sorCol: sortColumn === "group_admin_email" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },
    {
      Header: "Date",
      accessor: "group_date",
      sortdir: sortColumn === "group_date" ? sortDirection : "",
      sorCol: sortColumn === "group_date" ? "yes" : "no",
    },

    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
  ];

  const reportIndividual = [
    {
      Header: "Status",
      accessor: "status",
      sortdir: sortColumn === "status" ? sortDirection : "",
      sorCol: sortColumn === "status" ? "yes" : "no",
    },
    {
      Header: "Product",
      accessor: "product",
      sortdir: sortColumn === "product" ? sortDirection : "",
      sorCol: sortColumn === "product" ? "yes" : "no",
    },
    {
      Header: "Client",
      accessor: "client",
      sortdir: sortColumn === "client" ? sortDirection : "",
      sorCol: sortColumn === "client" ? "yes" : "no",
    },
    {
      Header: "Location",
      accessor: "location",
      sortdir: sortColumn === "location" ? sortDirection : "",
      sorCol: sortColumn === "location" ? "yes" : "no",
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortdir: sortColumn === "phone" ? sortDirection : "",
      sorCol: sortColumn === "phone" ? "yes" : "no",
    },
    {
      Header: "Email",
      accessor: "email",
      sortdir: sortColumn === "email" ? sortDirection : "",
      sorCol: sortColumn === "email" ? "yes" : "no",
    },
    {
      Header: "Generator",
      accessor: "generator",
      sortdir: sortColumn === "generator" ? sortDirection : "",
      sorCol: sortColumn === "generator" ? "yes" : "no",
    },
    {
      Header: "Date",
      accessor: "report_date",
      sortdir: sortColumn === "report_date" ? sortDirection : "",
      sorCol: sortColumn === "report_date" ? "yes" : "no",
    },

    {
      Header: "Activity",
      accessor: "activity",
      sortdir: sortColumn === "activity" ? sortDirection : "",
      sorCol: sortColumn === "activity" ? "yes" : "no",
    },
  ];

  // console.log("pagedata", pageData);

  // console.log("props.leadType",props.leadType)s
  const columns = useMemo(() => {
    // const result =
    //   props.leadType === "all"
    //     ? AllColumn
    //     : AllColumn.filter((data, i) => {
    //         return i !== 10;
    //       });
    // AllColumn.filter((data, i) => {
    //   return i !== 10;
    // });
    // return result;

    if (props.leadType === "my") {
      if (pageData?.status === "Opportunity Open") {
        return OpportunityOpen;
      }
      else if (pageData?.status === "Account Pending") {
        return AccountPending;
      }
    } else if (props.leadType === "all") {
      return AllColumn;
    } else if (props.leadType === "report-group") {
      return report;
    } else if (props.leadType === "report") {
      return reportIndividual;
    }
  });

  const getMonthName = (monthname) => {
    const date = new Date();
    date.setMonth(monthname - 1);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const activityDate = (dateActivity) => {
    if (dateActivity) {
      const times = dateActivity.split(" ")[1];
      const dates = dateActivity.split(" ")[0];
      const [yy, mm, dd] = dates.split("-");
      var months = getMonthName(mm);
      var originalDate = `${dd}-${months}-${yy}`;
      var originalActivityDate = originalDate + " " + times;
      return originalActivityDate;
    }
  };

  let claim_leads = useMemo(() => [], [RefreshTable]);
  let checked;
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);

  useEffect(() => {
    if (loadingData) {
      dispatch(
        getProducts(props.api, "created_at", "DESC", headers, props.filterData)
      );
    }
  }, [currentPage, sortDirection, LeadFilter, RefreshTable]);

  // useEffect(() => {
  //   dispatch(TableSliceAction.reset());
  // }, []);

  // ${info?.followup_day != null ? info?.followup_day + '-' + info?.followup_month + '-' + info?.followup_year : ""}`,
  const handleRowClick = (rowData) => {
    // console.log("rowData", rowData);

    console.log("rowData", rowData);
    if (props?.leadType === "report-group") {
      history.push({
        pathname: `/admin/group-opportunities/${rowData.group_id}`,
        state: { opportunitiesStatus: rowData.status },
      });
    } else {
      history.push({
        pathname: `/admin/opportunities/${rowData.client_id}`,
        state: { opportunitiesStatus: rowData.status },
      });
    }
  };

  const formatRowData = (rawData) =>
    rawData.map((info) => ({
      status: info.status,
      product: info.product,
      client: info.client,
      group_name: info.group_name,
      location: info.location,
      group_location: info.group_location,
      phone: info.phone,
      email: info.email,
      generator: info.generator,
      created_at: activityDate(info?.added_at),
      report_date: info?.added_at,
      activity: info.activity,
      group_admin_phone: info.group_admin_phone,
      group_admin_email: info.group_admin_email,
      // followUpDate: `${
      //   info?.followup_day != null
      //     ? info?.followup_day +
      //       "-" +
      //       getMonthName(info?.followup_month) +
      //       "-" +
      //       info?.followup_year
      //     : ""
      // }`,
      priority: info?.priority,
      // priority:info?.priority,
      group_date: info?.added_at,
      followUpDate: info.follow_up_date,
      client_id: info.client_id,
      group_id: info?.group_id,
      start_date: info?.start_date,
      insurer_name: info?.insurer_name,
      current_status_date: activityDate(info?.current_status_date),
      
      activity_date: activityDate(info?.activity_date),

      url: `/admin/opportunities/${info?.client_id}`,
      groupurl: `/admin/group-opportunities/${info?.group_id}`,

      actions: (
        <>
          {/* {props.leadType === "all" ? (
            <FormGroup check inline style={{ display: "table-cell" }}>
              <Label check style={{ marginBottom: "14px" }}>
                <Input
                  type="checkbox"
                  key={info.id}
                  value={info.id}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      claim_leads.push(e.target.value);
                      setSelectedLeads(claim_leads);
                    } else {
                      var index = claim_leads.indexOf(e.target.value);
                      if (index > -1) {
                        claim_leads.splice(index, 1);
                        setSelectedLeads(claim_leads);
                      }
                    }
                  }}
                />
                <span className="form-check-sign" />
              </Label>
              <Link
                className="btn btn-primary btn-sm mt-2"
                to={{
                  pathname: "/admin/opportunities/" + info.client_id,
                  opportunitiesStatus: info.status,
                }}
              >
                Detail
              </Link>
            </FormGroup>
          ) : (
            <Link
              className="btn btn-primary btn-sm"
              to={{
                pathname: "/admin/opportunities/" + info.client_id,
                opportunitiesStatus: info.status,
              }}
            >
              Detail
            </Link>
            
          )} */}

          {props.leadType === "all" && (
            <FormGroup
              check
              inline
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "29px",
              }}
            >
              <Label check style={{ marginBottom: "14px" }}>
                <Input
                  type="checkbox"
                  key={info.id}
                  value={info.id}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      claim_leads.push(e.target.value);
                      setSelectedLeads(claim_leads);
                    } else {
                      var index = claim_leads.indexOf(e.target.value);
                      if (index > -1) {
                        claim_leads.splice(index, 1);
                        setSelectedLeads(claim_leads);
                      }
                    }
                  }}
                />
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          )}
        </>
      ),
    }));
  const claimLeads = () => {
    if (SelectedLeads.length > 0) {
      let data = { ids: SelectedLeads };
      fetch(process.env.REACT_APP_API_URL + "/claim-leads", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            notify(data.message, 2, notificationAlert);
            setSelectedLeads([]);
            setRefreshTable(data.refresh);
          }
        });
    } else {
      notify("Please select opportunity.", 3, notificationAlert);
    }
  };

  const deleteLeads = () => {
    if (SelectedLeads.length > 0) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete()}
          onCancel={() => hideAlert("no")}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this!
        </ReactBSAlert>
      );
    } else {
      notify("Please select opportunity.", 3, notificationAlert);
    }
  };
  const successDelete = () => {
    fetch(process.env.REACT_APP_API_URL + "/delete-leads", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ ids: SelectedLeads }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
        } else {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no")}
              onCancel={() => hideAlert("no")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
          setRefreshTable(data.refresh);
        }
      });
  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };

  const getData = async (pageNo = 1, sortOrder = "ASC", column = "title") => {
    dispatch(
      TableSliceAction.updateTableConfig({ sCol: column, sDir: sortOrder })
    );
  };
  const updatePage = async (pageNo = 1) => {
    dispatch(TableSliceAction.updatePage(pageNo));
  };
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      {alert}
      <Row>
        <Col md="6">
          <p>Total Records: {pageData.totalRecords===null ? "Loading..." : pageData.totalRecords}</p>
        </Col>

        {props.leadType === "all" ? (
          <Col md="6" className="text-right">
            <Button color="primary" onClick={() => claimLeads()}>
              Claim Opportunities
            </Button>
            {loggedUser.data.role === 1 ? (
              <Button color="danger" onClick={() => deleteLeads()}>
                Delete Opportunities
              </Button>
            ) : null}
          </Col>
        ) : null}
      </Row>
      {props.api === "search" ? null : (
        <OpportunityFilter
          countryOption={countryOption}
          Staffoptions={consultantOptions}
          Supportoptions={supporManagerOptions}
          leadType={props.leadType}
        />
      )}
      <Row>
        <Col md="12">
          {columns && (
            <AppTable
              columns={columns}
              data={formatRowData(pageData.rowData)}
              isLoading={pageData.isLoading}
              sorting={getData}
              page={currentPage}
              onRowClick={handleRowClick}
            />
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md="12">
          <ServerPagination
            totalRows={pageData.totalRecords}
            pageChangeHandler={updatePage}
            rowsPerPage={100}
          />
        </Col>
      </Row>
    </>
  );
}
export default Opportunities;
